import AOS from "aos";
import "aos/dist/aos.css";
import "rc-slider/assets/index.css";
import { useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ExploreMostPopularCars from "../../../components/cars/home/ExploreMostPopularCars";
import FactsByTheNumbers from "../../../components/cars/home/FactsByTheNumbers";
import HomeBanner from "../../../components/cars/home/HomeBanner";
import HowItWorks from "../../../components/cars/home/HowItWorks";
import PopularCarTypes from "../../../components/cars/home/PopularCarTypes";
import RecommendedCars, { RecommendedCarsProps } from "../../../components/cars/home/RecommendedCars";
import TransparencyExplanation from "../../../components/cars/home/TransparencyExplanation";
import VehicleSearchFilters from "../../../components/cars/home/VehicleSearchFilters";
import WhyChooseUs from "../../../components/cars/home/WhyChooseUs";
import { setPageTitleAndRegisterGAEvent } from "../../../utils";
import Footer from "../../common/footer";
import Header from "../../common/header";

// interface Benefit {
//     id: number;
//     title: { rendered: string };
//     content: { rendered: string };
// }

// interface VehicleSearchFiltersProps {
//     type: string;
//     selectedModel: any;
//     setSelectedModel: (model: any) => void;
//     selectedBrand: any;
//     setSelectedBrand: (brand: any) => void;
//     priceRange: { min: number; max: number };
//     setPriceRange: (range: { min: number; max: number }) => void;
//     isNew: any;
//     setIsNew: (isNew: any) => void;
//     location: string;
//     setLocation: (location: string) => void;
//     onPriceChange: (value: [number, number]) => void;
// }

const defaultProps: RecommendedCarsProps = {
    rentalSliderOptions: {},
    selectedItems: [],
    handleItemClick: () => { }
};

const HomeOne = () => {    
    const [selectedModel, setSelectedModel] = useState<string | null>(null);
    const [selectedBrand, setSelectedBrand] = useState<{ id: number; name: string } | null>(null);    
    const [isNew, setIsNew] = useState<boolean>(false);
    const [location, setLocation] = useState("");
    const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });
    const onPriceChange = (value: number | number[]) => {
        if (Array.isArray(value) && value.length === 2) {
            setPriceRange({ min: value[0], max: value[1] });
        } else {
            console.error("Error: Slider value is not an array of length 2.");
        }
    };
    // Título de la página
    const pageTitle = "Inicio - Cuotaplan";
    
    useEffect(() => {
        AOS.init({ duration: 1200, once: true });                
        setPageTitleAndRegisterGAEvent(
            pageTitle,
            "Pagina",
            "Visita", 1);        
        
    }, [pageTitle]);
    return (
        <>
            <Header />            
            {/* /Banner */}
            <HomeBanner />
            {/* /Banner */}
            {/* Search Filters */}
            <VehicleSearchFilters
                type='vehicles'
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                selectedBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
                priceRange={priceRange}
                setPriceRange={setPriceRange}
                isNew={isNew}
                setIsNew={setIsNew}
                location={location}
                setLocation={setLocation}
                onPriceChange={onPriceChange}
            />
            {/* /Search Filters */}
            {/* How It Works */}
            <HowItWorks />
            {/* How It Works */}
            {/* Transparency and clarity */}
            <TransparencyExplanation />
            {/* /Transparency and clarity */}
            {/* Why Choose Us */}
            <WhyChooseUs />
            {/* Why Choose Us */}
            {/* Explore Most Popular Cars */}
            <ExploreMostPopularCars />
            {/* Explore Most Popular Cars */}
            {/* Popular Car Types */}
            <PopularCarTypes />
            {/* Popular Car Types */}
            {/* Facts By The Numbers     */}
            <FactsByTheNumbers />
            {/* Facts By The Numbers */}
            {/* Recommended Car Rental deals */}
            <RecommendedCars {...defaultProps} />
            {/* Recommended Car Rental deals */}

            {/* Benefits Section */}
            {/* <section className="section benefits">
                <div className="container">
                    <div className="section-heading" data-aos="fade-down">
                        <h2>Beneficios Exclusivos</h2>
                        <p>
                            Descubre cómo CuotaPlan te ayuda a maximizar tus beneficios
                            mientras accedes a tu vehículo nuevo.
                        </p>
                    </div>
                    <div className="row">
                        {benefits.map((benefit) => (<BenefitCard benefit={benefit} />))}
                    </div>
                </div>
            </section> */}
            {/* /Benefits Section */}

            <Footer />
        </>
    );
};

export default HomeOne;
