import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getMediaByNameSize } from "../../../../api/wordpress/posts";
import ImageWithBasePath from "../../../../core/data/img/ImageWithBasePath";
import { Dealer } from "../../../../types/cars-types";

interface DealerDetailsProps {

    dealer: Dealer;

}



const DealerDetails: React.FC<DealerDetailsProps> = ({ dealer }) => {

    const [logoSrc, setLogoSrc] = useState<string>("");

    useEffect(() => {
        const fetchLogo = async () => {
            const logo = await getMediaByNameSize(dealer.logo, "logotipo");
            setLogoSrc(logo);
        };

        fetchLogo();
    }, [dealer.logo]);                            
    return (
        <div className="review-sec extra-service mt-0">
            <div className="review-header">
                <h4>Concesionario</h4>
            </div>
            <div className="dealer-detail">
                <div className="dealer-img">
                    <Link to="#">
                        <ImageWithBasePath
                            // src="assets/img/profiles/dealer-avatar.jpg"
                            // src={await getMediaByNameSize(dealer.logo, "thumbnail")}
                            src={logoSrc}
                            alt={dealer.name}
                        />
                    </Link>
                    <span className="badge-check">
                        <ImageWithBasePath
                            src="assets/img/icons/badge-check.svg"
                            alt="Verificado"
                        />
                    </span>
                </div>
                {/* <div className="reviewbox-list-rating">
                    <h5>
                        <Link to="#">Concesionarios Premium</Link>
                    </h5>
                    <p>
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <span> (4.9)</span>
                    </p>
                </div> */}
            </div>
            <ul className="booking-list">
                <li>
                    Email
                    <span>{dealer.email}</span>
                </li>
                <li>
                    Teléfono
                    <span>{dealer.phone}</span>
                </li>
                <li>
                    Dirección
                    <span>{dealer.fullAddress}</span>
                </li>
            </ul>
            {/* <div className="message-btn">
                <Link to="#" className="btn btn-order">
                    Contactar Concesionario
                </Link>
                <Link to="#" className="chat-link">
                    <i className="fa-brands fa-whatsapp" />
                    Chat Vía Whatsapp
                </Link>
            </div> */}
        </div>
    );
};

export default DealerDetails;